/* App.css */

.App {
  text-align: center;
}

.App-header {
  background-color: #4CAF50; /* Green background */
  color: #555;
  padding: 20px;
  text-align: center;
}


.App-description {
  margin-top: 200px;
  font-size: 36px;
  color: whitesmoke;
  text-align: center;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}



.App-name {
  font-size: 1.5em;
  font-weight: bold;
}

.App-login-button {
  background-color: #28a745; /* Bootstrap green */
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 10px;
  cursor: pointer;
  border-radius: 5px;
}

.App-login-button:hover {
  background-color: #45a049;
}

body {
  background: linear-gradient(to bottom, #4CAF50, #2E8B57);
  font-family: 'Arial', sans-serif;
}


.App-footer {
  text-align: center;
  padding: 20px;
  background-color: #333;
  color: white;
  margin-top: 724px;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  max-width: 90%;
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  z-index: 1000;
  overflow: auto;
  outline: none;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 999;
}

input {
  display: block;
  width: calc(100% - 20px);
  padding: 10px;
  margin: 10px 0;
  font-size: 1em;
}

button {
  padding: 10px 20px;
  margin: 10px 0;
  cursor: pointer;
}

.link-button {
  background: none;
  color: #007bff;
  border: none;
  cursor: pointer;
  text-decoration: underline;
}

.link-button:hover {
  text-decoration: none;
}
