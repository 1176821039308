.profile {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.profile h2 {
    text-align: center;
    margin-bottom: 20px;
}

.profile form div {
    margin-bottom: 15px;
}

.profile label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
}

.profile input,
.profile button {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.profile button {
    background-color: #4caf50;
    color: white;
    font-size: 16px;
    cursor: pointer;
}

.profile button:hover {
    background-color: #45a049;
}

.plant-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.plant-list li {
    padding: 10px 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.plant-list li:hover {
    background-color: #f0f0f0;
    transform: scale(1.05);
}

.plant-list li.selected-plant {
    background-color: #d4edda;
    border-color: #28a745;
    color: #155724;
    transform: scale(1.05);
}