.App {
    text-align: center;
}

.App-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #f1f1f1;
}

.App-header h1 {
    margin: 0;
    font-size: 24px;
}

.profile-btn, .logout-btn {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 10px;
}

.profile-btn:hover, .logout-btn:hover {
    background-color: #45a049;
}

.Dashboard-main {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
}

.prediction-section {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    margin: 20px auto;
    width: 80%;
    max-width: 600px;
    text-align: left;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.prediction-section h2 {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
}

.results {
    margin-bottom: 20px;
}

.prediction-item {
    margin-bottom: 10px;
    font-size: 16px;
}

.fetch-btn {
    display: block;
    background-color: #4caf50;
    color: white;
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 auto;
}

.fetch-btn:hover {
    background-color: #45a049;
}

.weather-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
}

.weather-boxes {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.weather-box {
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    width: 200px;
}